import {
  createStyles,
  Title,
  rem,
  Container,
  Text,
  Flex,
  UnstyledButton,
} from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { useMediaQuery } from "@mantine/hooks"
import { Link } from "react-scroll"

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100vh",
    maxHeight: 1250,
    overflow: "hidden",
    position: "relative",
    transition: "all o.3s ease-in-out",
    display: "flex",
    flexDirection: "column",

    [theme.fn.smallerThan(1024)]: {
      height: "auto",
    },

    [theme.fn.smallerThan(600)]: {
      minHeight: "100vh",
    },
  },

  inner: {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",

    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(assets/bg.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "bottom center",
      zIndex: -1,
    },
  },

  backText: {
    bottom: 100,
    left: 60,
    position: "absolute",
    color: theme.white,
    fontSize: 230,
    fontWeight: 900,
    filter: "drop-shadow(0 0 80px rgba(34,139,230,.8))",
    lineHeight: 1.3,
    letterSpacing: 10,
    opacity: 0.2,
    zIndex: -1,

    [theme.fn.smallerThan(1280)]: {
      fontSize: 180,
    },

    [theme.fn.smallerThan(1024)]: {
      height: "auto",
      bottom: 60,
      fontSize: 160,
    },

    [theme.fn.smallerThan("md")]: {
      fontSize: 120,
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: 80,
      bottom: 40,
    },

    [theme.fn.smallerThan(680)]: {
      display: "none",
    },
  },

  container: {
    maxWidth: 1320,
    paddingTop: 50,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    position: "relative",
    flex: 1,

    [theme.fn.smallerThan(768)]: {
      paddingTop: 80,
    },

    [theme.fn.smallerThan(1024)]: {
      alignItems: "center",
    },

    [theme.fn.smallerThan(600)]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  majorTitle: {
    lineHeight: 1.1,
    fontSize: rem(96),
    fontWeight: 700,
    letterSpacing: 3,
    width: "fit-content",

    [theme.fn.smallerThan(1280)]: {
      fontSize: rem(70),
    },

    [theme.fn.smallerThan("md")]: {
      fontSize: 55,
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: 48,
      letterSpacing: 1,
    },

    [theme.fn.smallerThan(600)]: {
      marginTop: 16,
      textAlign: "center",
      width: "auto",
    },
  },

  secondWord: {
    lineHeight: 1.1,
    fontSize: rem(96),
    fontWeight: 700,
    letterSpacing: 3,
    paddingLeft: 120,
    width: "fit-content",

    [theme.fn.smallerThan(1280)]: {
      fontSize: rem(70),
      paddingLeft: 60,
    },

    [theme.fn.smallerThan("md")]: {
      fontSize: 55,
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: 48,
      letterSpacing: 1,
    },

    [theme.fn.smallerThan(680)]: {
      paddingLeft: 24,
    },

    [theme.fn.smallerThan(600)]: {
      marginBottom: 16,
      paddingLeft: 0,
      textAlign: "center",
      width: "auto",
    },
  },

  minorTitle: {
    opacity: 0.7,
    fontSize: 20,

    [theme.fn.smallerThan("md")]: {
      fontSize: 18,
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },

    [theme.fn.smallerThan(600)]: {
      textAlign: "center",
    },
  },

  contacts: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    maxWidth: 1320,
    minHeight: 50,
  },

  accent: {
    fontSize: 24,
    fontWeight: 600,

    [theme.fn.smallerThan(1280)]: {
      fontSize: 20,
    },

    [theme.fn.smallerThan("md")]: {
      fontSize: 18,
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },
  },

  firstBox: {
    padding: "12px 24px",
    borderRadius: 4,
    background: theme.fn.gradient({ from: theme.colors.orange[6], to: theme.colors.red[6], deg: 90 }),
    color: theme.white,
    width: "fit-content",

    [theme.fn.smallerThan("md")]: {
      padding: "8px 16px",
    },

    [theme.fn.smallerThan(768)]: {
      padding: "6px 12px",
    },
  },

  content: {
    paddingTop: 220,

    [theme.fn.smallerThan("md")]: {
      paddingTop: 160,
    },

    [theme.fn.smallerThan(1024)]: {
      paddingTop: 0,
    },

    [theme.fn.smallerThan(600)]: {
      marginTop: 24,
    },
  },

  secondBox: {
    padding: "12px 24px",
    borderRadius: 4,
    background: theme.fn.gradient({ from: theme.colors.cyan[6], to: theme.colors.blue[6], deg: 90 }),
    width: "fit-content",
    marginLeft: 120,
    marginTop: 12,
    color: theme.white,

    [theme.fn.smallerThan(1280)]: {
      marginLeft: 60,
    },

    [theme.fn.smallerThan("md")]: {
      padding: "8px 16px",
    },

    [theme.fn.smallerThan(768)]: {
      padding: "6px 12px",
    },

    [theme.fn.smallerThan(680)]: {
      marginLeft: 24,
    },

    [theme.fn.smallerThan(375)]: {
      marginLeft: 0,
    },
  },

  image: {
    maxWidth: "50%",
    paddingTop: 50,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "40%",
    },

    [theme.fn.smallerThan(768)]: {
      paddingTop: 24,
    },

    [theme.fn.smallerThan(600)]: {
      width: "100%",
      maxWidth: 380,
    },
  },

  infoBox: {
    marginTop: 48,

    [theme.fn.smallerThan(768)]: {
      marginTop: 24,
    },

    [theme.fn.smallerThan(600)]: {
      marginTop: 16,
    },
  },
}))

export function Hero(): React.JSX.Element {
  const { classes } = useStyles()
  const inMd = useMediaQuery(`(min-width: 768px)`)
  const md = useMediaQuery(`(max-width: 768px)`)
  const xs = useMediaQuery(`(max-width: 576px)`)
  const offset = md ? -48 : xs ? -36 : -72

  return (
    <section id="main" className={classes.wrapper}>
      <div className={classes.inner}/>
      <div className={classes.backText}>FITNESS</div>

      {inMd && (
        <Container className={classes.contacts}>
          <Text size="sm" c="dimmed">
            Body Coach — образовательная платформа №1
            в сфере фитнеса и нутрициологии
          </Text>
        </Container>
      )}
      <Container px="md" className={classes.container}>
        <Flex direction="column" className={classes.content}>
          <Text className={classes.minorTitle}>
            Получите востребованную профессию
          </Text>
          <Title
            variant="gradient"
            gradient={{ from: "indigo", to: "cyan", deg: 140 }}
            order={1}
            className={classes.majorTitle}
          >
            Фитнес
          </Title>
          <Title
            variant="gradient"
            gradient={{ from: "cyan", to: "indigo", deg: 140 }}
            order={1}
            className={classes.secondWord}
          >
            Тренер
          </Title>

          <Flex className={classes.infoBox} direction="column">
            <UnstyledButton
              className={classes.firstBox}
              to={"prices"}
              smooth={true}
              duration={1000}
              component={Link}
              offset={offset}
            >
              <Text className={classes.accent}>
                Старт обучения — 8 февраля
              </Text>
            </UnstyledButton>
            <UnstyledButton
              to={"program"}
              smooth={true}
              duration={1000}
              component={Link}
              offset={offset}
              className={classes.secondBox}
            >
              <Text className={classes.accent}>
                Продолжительность — 6 месяцев
              </Text>
            </UnstyledButton>
          </Flex>

        </Flex>
        <Image className={classes.image} src="assets/pngwing.com (2).png" objectFit="contain"/>
      </Container>
    </section>
  )
}
