import { createStyles, Accordion, Container, Title, Flex, Text } from "@mantine/core"
import { Image } from "../../shared/ui"
import React from "react"

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: `calc(${theme.spacing.xl} * 4)`,
    marginBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      marginTop: `calc(${theme.spacing.xl} * 2)`,
      marginBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      marginTop: `calc(${theme.spacing.xl} * 1.5)`,
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    marginBottom: 48,
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 32,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },

    [theme.fn.smallerThan(768)]: {
      textAlign: "center",
    },
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[7],
  },

  mainFlex: {
    gap: 48,

    [theme.fn.smallerThan(768)]: {
      flexDirection: "column",
    },
  },

  leftFlex: {
    width: "50%",
    alignSelf: "flex-start",

    [theme.fn.smallerThan(768)]: {
      width: "100%",
    },
  },

  rightFlex: {
    width: "50%",

    [theme.fn.smallerThan(768)]: {
      width: "100%",
    },
  },
}))

export function Faq() {
  const { classes } = useStyles()

  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <Flex className={classes.mainFlex}>
          <Flex className={classes.leftFlex} align="center" justify="center">
            <Image objectFit="contain" src="assets/faq.svg" alt="Часто задаваемые вопросы"/>
          </Flex>

          <Flex className={classes.rightFlex} direction="column">
            <Title order={2} className={classes.title}>
              <Text span variant="gradient"
                    gradient={
                      {
                        from: "blue",
                        to: "cyan",
                        deg: 105,
                      }
                    }>Часто</Text> задаваемые вопросы
            </Title>

            <Accordion radius="xl" chevronPosition="right" defaultValue="1" variant="separated">
              <Accordion.Item className={classes.item} value="1">
                <Accordion.Control>Как записаться в ближайший поток и начать обучение?</Accordion.Control>
                <Accordion.Panel>Нажмите на кнопку "Оставить заявку" . После этого координаторы нашего учебного центра
                  свяжутся с вами в течение 10 минут, чтобы сориентировать вас по всем вопросам, которые у вас возникли
                  и отправят договор на оказание образовательных услуг.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="2">
                <Accordion.Control>Когда старт нового потока и сколько длится обучение?</Accordion.Control>
                <Accordion.Panel>Ближайший поток стартует 8 февраля 2025 года. Обучение длится 6
                  месяцев.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="3">
                <Accordion.Control>Какие документы я получу после прохождения обучения?</Accordion.Control>
                <Accordion.Panel>После прохождения курса и сдачи итогового экзамена - вы получаете диплом о
                  профессиональной переподготовке с присвоением квалификации "Фитнес-тренер" с занесением сведений о
                  документе об образовании в федеральный реестр образовательных документов . Данные документы дают вам
                  право официально вести профессиональную деятельность.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="4">
                <Accordion.Control>
                  Отличается ли по качеству дистанционное обучение на персонального тренера от живого обучения?
                </Accordion.Control>
                <Accordion.Panel>С учетом многолетнего опыта работы, наш образовательный центр максимально адаптировали
                  практические блоки обучения таким образом, что разбор техники выполнения упражнений ничем не уступает
                  живому формату обучения. Обозначили все векторы направления движений с дополнительными акцентами
                  важнейших нюансов. Все видеоматериалы доступны неограниченное количество времени, вы всегда можете
                  пересмотреть уроки в любое удобное для вас время. </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="5">
                <Accordion.Control>
                  Подойдет для учебная программа "новичку"?
                </Accordion.Control>
                <Accordion.Panel>Образовательный курс устроен таким образом, что подойдет как специалисту, который уже
                  имеет определенный уровень знаний в сфере фитнеса и работает тренером, так и тем, кто только хочет
                  освоить для себя новую профессию.

                  С каждым студентом работает личный куратор, который помогает усвоить учебный материал и отвечает на
                  все возникающие вопросы в процессе обучения.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="6">
                <Accordion.Control>Есть ли рассрочка? Какие условия?</Accordion.Control>
                <Accordion.Panel>В нашем образовательном центре есть возможность приобрести обучение в рассрочку. Без %
                  и переплат на срок до 6 месяцев. Вы выбираете комфортную для вас сумму платежа и приступаете к
                  обучению.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="7">
                <Accordion.Control>Можно ли вернуть деньги, если мне не подошло обучение?</Accordion.Control>
                <Accordion.Panel>Да, вы можете вернуть денежные средства, если вам не подошло обучение в течение 14 дней
                  с начала старта учебного потока. </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Flex>
        </Flex>
      </Container>
    </div>
  )
}
