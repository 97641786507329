import {
  createStyles,
  Text,
  Title,
  Box,
  Flex,
  rem,
  List,
  Group,
  ActionIcon,
} from "@mantine/core";
import { Image } from "../../shared/ui";
import { TeamUnit } from "../../shared/utils/constants";
import {
  IconPoint,
  IconBrandVk,
  IconBrandInstagram,
  IconBrandTelegram,
  IconBrandYoutube,
  IconWorldWww,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
  },

  outerContainer: {
    width: "100%",
    position: "relative",
  },

  innerContainer: {
    width: "100%",
    maxWidth: 1024,
  },

  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: 24,

    [theme.fn.smallerThan(860)]: {
      gap: 16,
    },

    [theme.fn.smallerThan(768)]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },

  image: {
    width: "calc((100% / 4) * 2)",
    maxWidth: 480,
    // boxShadow: theme.shadows.xl,

    [theme.fn.smallerThan(1024)]: {
      maxWidth: 360,
    },

    [theme.fn.smallerThan(860)]: {
      maxWidth: 300,
    },

    [theme.fn.smallerThan(768)]: {
      width: "100%",
      maxWidth: 480,
      order: 0,
    },
  },

  title: {
    marginBottom: 50,
    textAlign: "right",
    width: "calc((100% / 4) * 1)",
    fontSize: rem(32),
    fontWeight: 700,
    alignSelf: "flex-end",

    [theme.fn.smallerThan(860)]: {
      marginBottom: 30,
      fontSize: rem(24),
    },

    [theme.fn.smallerThan(768)]: {
      order: 1,
      width: "100%",
      marginBottom: 0,
      textAlign: "center",
      alignSelf: "unset",
    },
  },

  right: {
    paddingTop: 50,
    width: "calc((100% / 4) * 1)",

    [theme.fn.smallerThan(860)]: {
      paddingTop: 30,
    },

    [theme.fn.smallerThan(768)]: {
      order: 2,
      width: "100%",
      paddingTop: 0,
      maxWidth: 480,
    },
  },

  description: {
    fontWeight: 500,
    paddingBottom: 16,
    position: "relative",
    fontSize: 18,

    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: 1,
      bottom: 0,
      left: 0,
      backgroundColor: "#e6e6e6",
    },

    [theme.fn.smallerThan(1024)]: {
      fontSize: rem(16),
    },

    [theme.fn.smallerThan(860)]: {
      fontSize: rem(14),
    },

    [theme.fn.smallerThan(768)]: {
      textAlign: "center",
      color: theme.colors.gray[6],
      fontSize: rem(16),
      fontWeight: 400,

      "&::before": {
        content: '""',
        height: 0,
      },
    },
  },

  listBox: {
    width: "100%",
    maxWidth: 768,
    marginTop: 48,

    [theme.fn.smallerThan(860)]: {
      marginTop: 32,
    },

    [theme.fn.smallerThan(768)]: {
      marginTop: 24,

      ".mantine-List-root": {
        li: {
          marginTop: 12,
          fontSize: 14,
        },
      },
    },
  },

  box: {
    position: "relative",
    zIndex: 0,
    width: "100%",
    maxWidth: 768,

    display: "flex",
    justifyContent: "center",
  },

  links: {
    marginTop: 16,

    [theme.fn.smallerThan(768)]: {
      justifyContent: "center",
      marginTop: 0,
    },
  },
}));

export function ArticleCardImage({
  name,
  texts,
  cover,
  description,
  links,
}: TeamUnit) {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Flex
        direction="column"
        align="center"
        className={classes.outerContainer}
      >
        <Flex
          direction="column"
          align="center"
          className={classes.innerContainer}
        >
          <Flex className={classes.container}>
            <Title className={classes.title}>{name}</Title>

            <Image
              ratio="1/1"
              sx={{ borderRadius: 4 }}
              className={classes.image}
              alt={name}
              src={cover}
            />

            <Box className={classes.right}>
              <Text className={classes.description}>{description}</Text>

              {links && (
                <Group className={classes.links}>
                  {links.vk && (
                    <ActionIcon
                      radius={4}
                      size="lg"
                      color="blue"
                      variant="filled"
                      component="a"
                      href={links.vk}
                      target="_blank"
                    >
                      <IconBrandVk size="1.2rem" />
                    </ActionIcon>
                  )}
                  {links.instagram && (
                    <ActionIcon
                      radius={4}
                      size="lg"
                      color="grape"
                      variant="filled"
                      component="a"
                      href={links.vk}
                      target="_blank"
                    >
                      <IconBrandInstagram size="1.2rem" />
                    </ActionIcon>
                  )}
                  {links.telegram && (
                    <ActionIcon
                      radius={4}
                      size="lg"
                      color="cyan"
                      variant="filled"
                      component="a"
                      href={links.telegram}
                      target="_blank"
                    >
                      <IconBrandTelegram size="1.2rem" />
                    </ActionIcon>
                  )}
                  {links.youtube && (
                    <ActionIcon
                      radius={4}
                      size="lg"
                      color="red"
                      variant="filled"
                      component="a"
                      href={links.youtube}
                      target="_blank"
                    >
                      <IconBrandYoutube size="1.2rem" />
                    </ActionIcon>
                  )}
                  {links.personal && (
                    <ActionIcon
                      radius={4}
                      size="lg"
                      color="gray"
                      variant="filled"
                      component="a"
                      href={links.personal}
                      target="_blank"
                    >
                      <IconWorldWww size="1.2rem" />
                    </ActionIcon>
                  )}
                </Group>
              )}
            </Box>
          </Flex>

          <Box className={classes.listBox}>
            <List
              spacing="md"
              size="md"
              icon={<IconPoint size={24} style={{ color: "#228be6" }} />}
            >
              {texts.map((text, index) => (
                <List.Item key={index}>{text}</List.Item>
              ))}
            </List>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
