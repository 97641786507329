import React from "react"
import {
  Container,
  createStyles,
  Title,
  Flex,
  Text,
  List,
  ThemeIcon,
  Center,
} from "@mantine/core"
import { PricingFreeSection } from "./PricingFreeSection"
import { IconCalendar, IconRocket, IconDeviceComputerCamera } from "@tabler/icons-react"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",

    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    lineHeight: 1,
    marginBottom: 48,
    fontSize: 48,
    marginTop: 24,
    position: "relative",

    "span": {
      lineHeight: 1.3,
    },

    [theme.fn.smallerThan(1180)]: {
      marginTop: 0,
      marginBottom: 24,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
    },

    [theme.fn.smallerThan(768)]: {
      marginBottom: 0,
    },
  },

  descBig: {
    maxWidth: 440,

    [theme.fn.smallerThan(1180)]: {
      display: "none",
    },
  },

  descSmall: {
    [theme.fn.largerThan(1180)]: {
      display: "none",
    },
  },

  mainFlex: {
    justifyContent: "space-evenly",
    gap: 24,

    [theme.fn.smallerThan(1180)]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 48,
    },
  },

  innerFlex: {
    flexDirection: "column",
    position: "relative",

    [theme.fn.smallerThan(1180)]: {
      flexDirection: "row",
      alignItems: "flex-start",
      gap: 48,
      margin: "0 auto",
      width: "100%",
      maxWidth: 768,
      justifyContent: "space-between",
      marginBottom: 24,
    },

    [theme.fn.smallerThan(870)]: {
      gap: 24,
      justifyContent: "center",
    },

    [theme.fn.smallerThan(768)]: {
      alignItems: "center",
      flexDirection: "column",
      marginBottom: 0,
    },
  },

  accentText: {
    fontSize: 20,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: 16,
    },
  },
}))

export function Prices(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`)
  const is1080 = useMediaQuery(`(min-width: 768px)`)
  const is540 = useMediaQuery(`(max-width: 540px)`)

  return (
    <section id="prices" className={classes.wrapper}>
      <Container size={1320} sx={{ position: "relative" }}>
        <Flex className={classes.mainFlex}>
          <Flex className={classes.innerFlex}>

            <Text className={classes.descBig} c="dimmed">
              Студенты могут вернуть до 13% стоимости курса, оформив налоговый вычет
            </Text>
            <Title className={classes.title}>
              <Text
                span
                variant="gradient"
                gradient={{
                  from: "blue",
                  to: "cyan",
                  deg: 105,
                }}
              >
                Стоимость&nbsp;
              </Text>
              {is1080 && <br/>}
              обучения
            </Title>

            <List center spacing={is540 ? 16 : 24}>
              <List.Item
                icon={
                  <ThemeIcon
                    size={isXs ? 28 : 36}
                    radius={4}
                    variant="light"
                  >
                    <IconRocket size={isXs ? 20 : 24}/>
                  </ThemeIcon>
                }
              >
                <Text size={isXs ? "sm" : "lg"} fw={400}>
                  Старт потока&nbsp;&nbsp;–&nbsp;&nbsp;
                  8&nbsp;февраля&nbsp;2025
                </Text>
              </List.Item>
              <List.Item
                icon={
                  <ThemeIcon
                    size={isXs ? 28 : 36}
                    radius={4}
                    variant="light"
                  >
                    <IconDeviceComputerCamera size={isXs ? 20 : 24}/>
                  </ThemeIcon>
                }
              >
                <Text size={isXs ? "sm" : "lg"} fw={400}>
                  Формат&nbsp;&nbsp;–&nbsp;&nbsp;
                  дистанционно
                </Text>
              </List.Item>
              <List.Item
                icon={
                  <ThemeIcon
                    size={isXs ? 28 : 36}
                    radius={4}
                    variant="light"
                  >
                    <IconCalendar size={isXs ? 20 : 24}/>
                  </ThemeIcon>
                }
              >
                <Text size={isXs ? "sm" : "lg"} fw={400}>
                  Длительность&nbsp;&nbsp;–&nbsp;&nbsp;
                  6&nbsp;месяцев
                </Text>
              </List.Item>
            </List>
          </Flex>
          <PricingFreeSection/>
        </Flex>

        <Center mt={32}>
          <Text align="center" className={classes.descSmall} c="dimmed">
            Студенты могут вернуть до 13% стоимости курса, оформив налоговый вычет
          </Text>
        </Center>

      </Container>
    </section>
  )
}
