import React from "react"
import { Button, Text, Paper, createStyles, Group, Flex, Indicator, Box } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { FormModalStore } from "../../app/FormModalStore"
import { IconBusinessplan, IconCalendarClock } from "@tabler/icons-react"

const useStyles = createStyles((theme) => ({
  flex: {
    position: "relative",
    zIndex: 0,
  },

  wrapper1: {
    flex: 1,
    width: "100%",
    zIndex: 2,
    position: "relative",
    maxWidth: 380,
    display: "flex",
    flexDirection: "column",
    background: "transparent",

    "&::after": {
      content: "''",
      backgroundColor: theme.colors.blue[0],
      height: "100%",
      left: 0,
      top: 0,
      position: "absolute",
      transform: "skewY(-5deg)",
      width: "100%",
      zIndex: -1,
      filter: "drop-shadow(0 0 30px rgba(34,139,230,0.3))",
      borderRadius: 4,
    },
  },

  innerBox1Header: {
    color: theme.white,
    padding: 30,
    position: "relative",
    zIndex: 0,
    minHeight: 159.188,
    display: "flex",
    alignItems: "center",

    [theme.fn.smallerThan(768)]: {
      minHeight: 134.38,
    },

    "&::after": {
      content: "''",
      background: theme.fn.linearGradient(90, theme.colors.blue[6], theme.colors.cyan[6]),
      height: "100%",
      left: 0,
      top: 0,
      position: "absolute",
      transform: "skewY(-5deg)",
      width: "100%",
      zIndex: -1,
      borderRadius: "4px 4px 0 0",
    },
  },

  innerBox2Header: {
    color: theme.white,
    padding: 30,
    position: "relative",
    zIndex: 0,
    minHeight: 159.188,
    display: "flex",
    alignItems: "center",

    [theme.fn.smallerThan(768)]: {
      minHeight: 134.38,
    },

    "&::after": {
      content: "''",
      backgroundColor: theme.black,
      height: "100%",
      left: 0,
      top: 0,
      position: "absolute",
      transform: "skewY(-5deg)",
      width: "100%",
      zIndex: -1,
      borderRadius: "4px 4px 0 0",
    },
  },

  wrapper2: {
    width: "100%",
    flex: 1,
    maxWidth: 380,
    position: "relative",
    zIndex: 3,
    display: "flex",
    flexDirection: "column",
    background: "transparent",

    "&::after": {
      content: "''",
      background: "#d3d3d3",
      height: "100%",
      left: 0,
      top: 0,
      position: "absolute",
      transform: "skewY(-5deg)",
      width: "100%",
      zIndex: -1,
      filter: "drop-shadow(0 0 30px rgba(0,0,0,0.3))",
      borderRadius: 4,
    },
  },

  innerBox1: {
    padding: 30,
    flex: 1,
  },

  innerBox2: {
    padding: 30,
    flex: 1,
  },

  mainFlex: {
    gap: 24,
  },

  indicator: {
    width: "50%",
    flex: 1,
    display: "flex",

    ".mantine-Indicator-indicator": {
      transform: "translate(-20%, -20%) skewY(-5deg)",
    },

    [theme.fn.smallerThan(540)]: {
      width: "100%",
      maxWidth: 320,
    },
  },

  wrapperFlex: {
    gap: 24,

    [theme.fn.smallerThan(768)]: {
      gap: 16,
    },

    [theme.fn.smallerThan(540)]: {
      flexDirection: "column",
      gap: 48,
    },
  },

  title: {
    fontWeight: 700,
    fontSize: 32,

    [theme.fn.smallerThan(768)]: {
      fontSize: 24,
    },
  },

  currentPrice: {
    fontWeight: 700,
    fontSize: 40,

    [theme.fn.smallerThan(768)]: {
      fontSize: 32,
    },
  },

  oldPrice: {
    fontWeight: 700,
    fontSize: 24,

    [theme.fn.smallerThan(768)]: {
      fontSize: 20,
    },
  },

  buttonWrapper: {
    marginBottom: 45,

    [theme.fn.smallerThan(768)]: {
      marginBottom: 30,
    },
  },
}))

export function PricingFreeSection() {
  const { classes } = useStyles()
  const matched = useMediaQuery(`(max-width: 768px)`)

  return (
    <Flex className={classes.wrapperFlex} align="stretch">
      <Indicator
        className={classes.indicator}
        color="red.8"
        position="top-start"
        radius={4}
        size={28}
        withBorder
        label="Скидка 15%"
      >
        <Paper radius={4} className={classes.wrapper1}>
          <div className={classes.innerBox1Header}>
            <Text className={classes.title}>
              Полная оплата
            </Text>
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: 24,
                opacity: 0.1,
              }}
            >
              <IconBusinessplan size={100}/>
            </Box>
          </div>
          <div className={classes.innerBox1}>
            <Text td="line-through" align="center" color="red.8" className={classes.oldPrice}>
              &nbsp;75 000 &#8381;&nbsp;
            </Text>
            <Text align="center" className={classes.currentPrice}>
              65 000 &#8381;
            </Text>
          </div>
          <Group className={classes.buttonWrapper} position="center">
            <Button
              variant="gradient"
              gradient={{ from: "blue", to: "cyan", deg: 90 }}
              size={matched ? "md" : "lg"}
              radius={4}
              onClick={() => FormModalStore.setData({ target: "Профессия: Фитнес-тренер. Блок: Стоимость обучения. Кнопка: Полная оплата" })}
            >
              Начать обучение
            </Button>
          </Group>
        </Paper>
      </Indicator>
      <Indicator
        className={classes.indicator}
        color="red.8"
        position="top-start"
        radius={4}
        size={28}
        withBorder
        label="Скидка 14%"
      >
        <Paper radius={4} className={classes.wrapper2}>
          <div className={classes.innerBox2Header}>
            <Text className={classes.title}>
              Беспроцентная рассрочка
            </Text>
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: 24,
                opacity: 0.1,
              }}
            >
              <IconCalendarClock size={100}/>
            </Box>
          </div>
          <div className={classes.innerBox2}>
            <Text
              td="line-through"
              align="center"
              className={classes.oldPrice}
              color="red.8"
            >
              &nbsp;12 500 &#8381;&nbsp;
            </Text>
            <Text align="center" className={classes.currentPrice}>
            11.666 &#8381;<Text span size="md"> / мес</Text>
            </Text>
            <Text fw={700} align="center" size="md">
              на 6 месяцев
            </Text>
          </div>
          <Group className={classes.buttonWrapper} position="center">
            <Button
              color="dark"
              radius={4}
              size={matched ? "md" : "lg"}
              onClick={() => FormModalStore.setData({ target: "Профессия: Фитнес-тренер. Блок: Стоимость обучения. Кнопка: Беспроцентная рассрочка на 6 мес." })}
            >
              Оставить заявку
            </Button>
          </Group>
        </Paper>
      </Indicator>
    </Flex>
  )
}
